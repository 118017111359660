import React from 'react';
import { graphql } from 'gatsby';

import * as Core from 'components/core';
import Layout from 'components/departments/DepartmentLayout';

import Heading from 'components/real-world/Heading';

interface Props {
  data: { nepal: Core.ImageAttributes; lauraLueken: Core.ImageAttributes };
}

const DepartmentContent: React.FC<Props> = (props) => {
  const { nepal, lauraLueken } = props.data;

  const headingSections: {
    title: string;
    slug: string;
    description: string;
  }[] = [
    {
      title: 'Student Internships',
      slug: 'student-internships',
      description:
        'An opportunity for real-world experience in addressing international policy challenges.'
    },
    {
      title: 'Student Practicum',
      slug: 'student-practicum',
      description:
        'An intensive — and extensive — chance to apply classroom knowledge to critical problems in the field.'
    }
  ];

  return (
    <Layout departmentSlug="real-world" title="Real World">
      <Heading
        title="Providing vital capital to small businesses in emerging markets around the world … addressing gender-based violence in Nepal."
        headingSections={headingSections}
      />

      <Core.ContentWrapper>
        <Core.ContentSection
          id="student-internships"
          showShareLink
          marginalia={
            <>
              <Core.FullWidthImage
                image={lauraLueken}
                alt="Laura Lueken"
                caption="Laura Lueken"
              />
            </>
          }
        >
          <Core.Flag>Student Internships</Core.Flag>
          <Core.SecondaryHeading>Micro Loans, Big Impact</Core.SecondaryHeading>
          <p>
            As an intern for MCE Social Capital, SAIS second-year master’s
            student <Core.BoldText>Laura Lueken</Core.BoldText> is getting
            hands-on experience in the field of impact investing, while helping
            to provide much-needed capital to small businesses in developing
            countries.
          </p>
          <p>
            “I come from a private-sector, business background, but I’m really
            interested in focusing that on economic growth and reaching
            underserved communities,” she says.
          </p>
          <p>
            Lueken first learned about the internship in early October 2020 when
            she attended an information session arranged by{' '}
            <Core.BoldText>Rebecca Aman</Core.BoldText>, associate director of
            global careers for SAIS. Lueken was impressed by MCE’s business
            model and found the presentation by Elisabeth Chasia, MCE principal,
            to be “really interesting and engaging.” After Lueken applied and
            was granted an interview, she worked closely with Aman, who helped
            her to prepare for the interview. At the interview, she was able to
            meet MCE’s Chief Investment Officer{' '}
            <Core.BoldText>Pierre Berard</Core.BoldText>, SAIS ’08, who was
            responsible for setting up the internship. By November, Lueken had
            landed the six-month internship and was working remotely for MCE,
            with Chasia as her new manager.
          </p>

          <p>
            According to Lueken, MCE has a unique model that involves leveraging
            the guarantee of financial individuals, foundations, and charitable
            trusts to obtain loans from financial institutions and accredited
            investors — and then deploying this money to microfinance
            institutions and small and growing businesses in emerging markets
            around the world.
          </p>

          <p>
            “It’s a way of getting small amounts of money to sectors that
            wouldn’t normally get it,” Lueken explains. For example, the capital
            might flow through a microfinance institution to pay for an
            individual’s school fees or to support a growing agricultural or
            waste disposal business.
          </p>

          <p>
            Through the internship, Lueken is gaining valuable real-world
            experience performing financial analysis and working on transactions
            with different companies around the world.
          </p>

          <p>
            “MCE has a really strong and supportive work culture, and I have
            really enjoyed working there,” she says. “The internship has been a
            fantastic experience.” <em>Will Howard</em>
          </p>
        </Core.ContentSection>

        <Core.HorizontalRule />

        <Core.ContentSection id="student-practicum" showShareLink>
          <Core.Flag>Student Practicum</Core.Flag>
          <Core.SecondaryHeading>
            Bringing Voice to the Voiceless in Nepal
          </Core.SecondaryHeading>

          <p>
            According to <Core.BoldText>Yiyuan Qi</Core.BoldText> ’20,
            gender-based violence is prevalent in Nepal, as in many countries,
            but it has become “even more problematic during COVID-19,” she says,
            exacerbated by the added stress of families staying at home.
          </p>

          <p>
            Gender-based violence was the focus of field research undertaken by
            Qi and her team members —{' '}
            <Core.BoldText>Leah Danville</Core.BoldText> ’20,{' '}
            <Core.BoldText>Emily Ing</Core.BoldText> ’20, and{' '}
            <Core.BoldText>Emily Koenig</Core.BoldText> ’20 — who traveled to
            Nepal for their SAIS Women Lead Practicum in January 2020, just
            before the pandemic hit.
          </p>

          <Core.FullWidthImage image={nepal} alt="Nepal" />

          <p>
            The students worked as consultants for the Nepal-based NGO Women’s
            Rehabilitation Center (WOREC) to help evaluate their programming on
            violence prevention and to create media dissemination strategies to
            better spread the center’s message and services.
          </p>
          <p>
            The students began their trip in Kathmandu, the capital of Nepal.
            After three days, they spent nine hours traveling through winding
            roads, up the side of mountains to the rural village of Udayapur in
            the southeast of Nepal.
          </p>
          <p>
            The drive “was beautiful but frightening at times — it required a
            lot of trust in the driver … and so there was a lot of praying going
            on,” say Danville, who was in the International Relations program at
            SAIS.
          </p>
          <p>
            In Udayapur, the team met with women and men who benefit from
            WOREC’s programs and services. WOREC provides technical trainings in
            agriculture, dhaka (a type of sewing), radio editing, and computer
            skills, in addition to awareness programs on gender and gender roles
            in order to prevent gender-based violence. The students also met
            with women who are survivors of such violence to hear their personal
            stories. Many of the women emphasized the importance of education
            and fiscal independence as the key for women’s empowerment and the
            prevention of gender-based violence.
          </p>
          <p>
            One of the women they interviewed was able to learn dhaka and now
            trains other women in her community to create and sell crafts. Her
            story is “a powerful example of women helping women,” says Qi, who
            concentrated in International Political Economy at SAIS.
          </p>
          <p>
            According to Danville, WOREC helps to raise awareness and “bring
            voice to those who have felt voiceless because they didn’t know they
            should have a voice against what is happening to them.”
          </p>
          <p>
            After three days in Udayapur, the students visited another rural
            area, Sindhuli, for two days, and then traveled back over the
            mountains to Kathmandu. For the last week, they spent time meeting
            with other NGOs that provide gender-based violence prevention and
            with local government officials. By conducting in-field research and
            travel to Nepal, the students were able to gain valuable skills of
            research design, data collection, and ethnographic research.
          </p>
          <p>
            Qi says the practicum trip has inspired her to “try to empower women
            through working on economic policy.”
          </p>
          <p>
            Says Danville, “The trip was extremely meaningful, and by far one of
            the most interesting, exciting, and heartfelt experiences that I’ve
            had in my life.” <em>Will Howard</em>
          </p>
        </Core.ContentSection>

        <Core.MoreResources>
          For more about students’ experiences in the field, visit{' '}
          <a
            href="https://snapshot.sais-jhu.edu/"
            target="_blank"
            rel="noopener noreferrer"
          >
            snapshot.sais-jhu.edu
          </a>
        </Core.MoreResources>
      </Core.ContentWrapper>
    </Layout>
  );
};

export const query = graphql`
  query($directory: String!) {
    nepal: file(
      relativePath: { regex: $directory }
      name: { eq: "Nepal_Image_2" }
    ) {
      ...ContentWidthImage
    }

    lauraLueken: file(
      relativePath: { regex: $directory }
      name: { eq: "laura_lueken" }
    ) {
      ...ContentWidthImage
    }
  }
`;

export default DepartmentContent;
